define("discourse/plugins/discourse-animated-avatars/discourse/templates/components/user-profile-avatar", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="user-profile-avatar">
    {{animated-bound-avatar @user "huge"}}
    <UserAvatarFlair @user={{@user}} />
    {{! template-lint-disable no-capital-arguments }}
    <PluginOutlet
      @name="user-profile-avatar-flair"
      @connectorTagName="div"
      @args={{hash model=@user}}
      @tagName="div"
    />
  </div>
  */
  {
    "id": "swDSWCCW",
    "block": "[[[10,0],[14,0,\"user-profile-avatar\"],[12],[1,\"\\n  \"],[1,[28,[35,0],[[30,1],\"huge\"],null]],[1,\"\\n  \"],[8,[39,1],null,[[\"@user\"],[[30,1]]],null],[1,\"\\n\"],[1,\"  \"],[8,[39,2],null,[[\"@name\",\"@connectorTagName\",\"@args\",\"@tagName\"],[\"user-profile-avatar-flair\",\"div\",[28,[37,3],null,[[\"model\"],[[30,1]]]],\"div\"]],null],[1,\"\\n\"],[13]],[\"@user\"],false,[\"animated-bound-avatar\",\"user-avatar-flair\",\"plugin-outlet\",\"hash\"]]",
    "moduleName": "discourse/plugins/discourse-animated-avatars/discourse/templates/components/user-profile-avatar.hbs",
    "isStrictMode": false
  });
});